import api from "@modules/api/api";
import userQueryKeys from "@modules/user/userQueryKeys";
import { useMutation, useQueryClient } from "@tanstack/react-query";

type RequestBody = {
  tradeInToken: string;
  source: string;
};

export type AssociateTradeInResponse = {
  dealId: string;
};

const performPost = async (
  body: RequestBody
): Promise<AssociateTradeInResponse> => {
  return api.post("api/purchases/associate-trade-in", {
    json: body,
  });
};

export default function useAssociateTradeInMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: performPost,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: userQueryKeys.user() });
    },
  });
}
